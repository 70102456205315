import React from 'react';
import * as styles from './author.module.scss';
import { Image } from '../../../hooks/gatsbyImg';

const Author = ({name, title, filename, width, height}) => {
    return (
        <div className={styles.container}>
            <Image filename={filename} width={width} height={height} className={styles.image} />
            <div className={styles.information}>
                <h3>{name}</h3>
                <p>{title}</p>
            </div>
        </div>
    );
};

export default Author;
