import React, {useState} from 'react';
import * as styles from './Members.module.scss';
import Member from "../../atoms/Member";
import {members} from '../../../constants/members';

const Members = () => {
    const [department, setDepartment] = useState(['Management','Technical','Marketing']);
    const [index, setIndex] = useState(0);
    return (
        <div className={styles.container}>
            <div className={styles.filters}>
                <h3
                    onClick={() => (setDepartment(['Management','Technical','Marketing']), setIndex(0))}
                    className={`${index == 0 && styles.active}`}
                >All teams</h3>
                <h3
                    onClick={() => (setDepartment(['Management']), setIndex(1))}
                    className={`${index == 1 && styles.active}`}
                >Management/Sales</h3>
                <h3
                    onClick={() => (setDepartment(['Technical']), setIndex(2))}
                    className={`${index == 2 && styles.active}`}
                >Technical</h3>
                <h3
                    onClick={() => (setDepartment(['Marketing']), setIndex(3))}
                    className={`${index == 3 && styles.active}`}
                >Marketing/Research/Design</h3>
            </div>
            <div  className={styles.membersContainer}>
                {members.filter(member => department.includes(member.department)).map((member) => {
                    return <Member title={member.title} name={member.name} image={member.image} />;
                })}
            </div>
        </div>

    );
};

export default Members;
