import React, {useEffect, useState} from 'react';
import * as styles from "./values.module.scss";
import SwipeableViews from "react-swipeable-views";
import Pagination from "../../atoms/Pagination";
import Value from "../../atoms/Value";

const Values = ({title, description, values, extraClass, numberColor}) => {
    const [width, setWidth] = useState(0);
    const [index, setIndex] = useState(0);
    const handleChangeIndex = (index) => {
        setIndex(index);
    };
    useEffect( () => {
        setWidth(window.innerWidth);
    },[]);

    const mobileView = () => {
        return (
            <div>
                <SwipeableViews index={index} onChangeIndex={handleChangeIndex}>
                    {
                        values.map(( value )=>{
                            return <Value
                                number={value.number}
                                title={value.title}
                                description={value.desc}
                                list={value?.list}
                                numberColor={numberColor}
                            />;
                        })
                    }
                </SwipeableViews>
                <Pagination dots={4} index={index}/>
            </div>
        );
    }

    const desktopView = () => {
        return (
            <div className={styles.desktopContainer}>
                {
                    values.map(( value )=>{
                        return <Value
                            number={value.number}
                            title={value.title}
                            description={value.desc}
                            list={value?.list}
                            numberColor={numberColor}
                        />;
                    })
                }
            </div>
        );
    }

    return (
        <div className={`${styles.container}  ${styles[extraClass]} `}>
            <div className={styles.innerContainer}>
                <h2>{title}</h2>
                {description && <p>{description}</p>}
                {width && width < 1024 ? mobileView() : desktopView()}
            </div>
        </div>
    );
};

export default Values;
