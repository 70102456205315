import * as React from "react";
import * as styles from './CardSlider.module.scss';
export default class CardSlider extends React.Component {
    constructor(props) {
        super(props);
        this.handlePrev = () => {
            let { activeIndex } = this.state;
            if (this.disablePrev)
                return;
            activeIndex = --activeIndex < 0 ? this.totalCount - 1 : activeIndex;
            this.setState({ activeIndex });
            this.handleChange(activeIndex);
        };
        this.handleNext = () => {
            let { activeIndex } = this.state;
            if (this.disableNext)
                return;
            activeIndex = ++activeIndex >= this.totalCount ? 0 : activeIndex;
            this.setState({ activeIndex });
            this.handleChange(activeIndex);
        };
        this.handleChange = index => {
            const { list, onChange } = this.props;
            onChange && onChange(index, list[index]);
        };
        this.state = {
            activeIndex: props.index || 0,
            moving: false
        };
    }
    componentWillReceiveProps(nextProps) {
        if (this.props.index !== nextProps.index) {
            this.setState({
                activeIndex: nextProps.index
            });
        }
    }
    get totalCount() {
        return this.props.list.length;
    }
    get gridWidth() {
        const isEven = this.totalCount % 2 === 0;
        const { width, boxWidth } = this.props;
        return ((boxWidth - width) / (isEven ? this.totalCount : this.totalCount - 1));
    }
    get disablePrev() {
        const { loop, disablePrev } = this.props;
        const { activeIndex } = this.state;
        if (disablePrev)
            return true;
        return !loop && activeIndex === 0;
    }
    get disableNext() {
        const { loop, disableNext } = this.props;
        const { activeIndex } = this.state;
        if (disableNext)
            return true;
        return !loop && activeIndex === this.totalCount - 1;
    }
    getDirection(index) {
        const { activeIndex } = this.state;
        let direction = 1;
        if (index - activeIndex > this.totalCount / 2 ||
            (index - activeIndex < 0 && index - activeIndex > -this.totalCount / 2)) {
            direction = -1;
        }
        let offset = Math.abs(index - activeIndex);
        if (offset > this.totalCount / 2) {
            offset = activeIndex + this.totalCount - index;
        }
        if (index - activeIndex < -this.totalCount / 2) {
            offset = this.totalCount + index - activeIndex;
        }
        return {
            direction,
            offset
        };
    }
    render() {
        const { list, renderItem, opacity, scale, width, boxWidth, style } = this.props;
        return (React.createElement("div", { className: styles.wrapper },
            React.createElement("div", { className:styles.content, style: Object.assign( { width: '50%' }) }, list.map((data, index) => {
                const { direction, offset } = this.getDirection(index);
                const realScale = Math.pow(scale, offset);
                return renderItem(Object.assign(Object.assign({ key: index }, data), { style: {
                        position: "absolute",
                        left: "50%",
                        borderRadius:15,
                        marginLeft: this.gridWidth * direction * offset +
                            direction * ((width / 2) * (1 - realScale)),
                        zIndex: this.totalCount - offset,
                        opacity: Math.pow(opacity, offset),
                        transform: `translateX(-50%) translateZ(0) scale(${realScale})`,
                        transition: "all 300ms"
                    } }));
            })),
            !this.disablePrev && (React.createElement("a", { href: "javascript:;", className: styles.btnLeft , onClick: this.handlePrev }, "<")),
            !this.disableNext && (React.createElement("a", { href: "javascript:;", className: styles.btnRight , onClick: this.handleNext }, ">"))));
    }
}
CardSlider.defaultProps = {
    opacity: 0.9,
    scale: 0.9,
    loop: false,
    disablePrev: false,
    disableNext: false
};