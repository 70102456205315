export const values = [
    {
        number: "1",
        title: "Shining Professionally",
        desc: 'Everyone deserves a chance to show their best side. We want students, freelancers, consultants, companies and organizations to experience a new level of communicating their messages.'
    },
    {
        number: "2",
        title: "Virtual better than Real",
        desc: 'The way we work and communicate is increasingly digital. We want offer new ways to create a virtual presence that is impactful, fun and productive.'
    },
    {
        number: "3",
        title: "Democratize Communications",
        desc: 'We take inspirations from the best communicators in the world and make it affordable. From the 1% best to the 99% rest. Experience what it means to be a Pro.'
    },
    {
        number: "4",
        title: "Diversity a Super Power",
        desc: 'New ways of virtual work allows for borderless collaboration. We want to create solutions that reflect and stimulates diversity and make it a new super tool.'
    },
]