import React from 'react';
import * as styles from './Member.module.scss';
import {Image} from '../../../hooks/gatsbyImg';

const Member = ({name, title, image}) => {
    return (
        <div className={styles.container}>
            <Image filename={image} className={styles.image} alt={title} />
            <h4>{name}</h4>
            <span>{title}</span>
        </div>
    );
};

export default Member;
