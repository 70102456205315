import React from 'react';
import CardSlider from "../../molecules/CardSlider";
import CardItem from "../../atoms/CardItem";
import * as styles from './CardSection.module.scss';

const CardSection = () => {

    const list = [
        { name: "Alborita",color:"#394A6E", desc: "Knowing that MASTER WiZR is a Revolutionary Brand Presentation Product that is changing the game in the business world attracted me for the very beginning. In MASTER WiZR I feel free and have the room to do my best and honesty is very appreciated." },
        { name: "Mathew",color:"#0695DB", desc: "I am constantly looking out for challenging yet meaningful software projects. Challenging in a way that they test my determination and skill, and drives me to learn and make life better for those that use the products. The MASTER WiZR suite is one of those rare solutions." },
        { name: "Esther",color:"#0695DB", desc: "I’m always looking for a place where I have the freedom to positively contribute to a solution, not limited by technology or project scope. MASTER WiZR is one of those that empower anyone on the team to grow technically, professionally, and in leadership and creativity." },
        { name: "Carsten",color:"#394A6E", desc: "Life is a journey of small steps, and large achievements consist of many small parts. My hope is that MASTER WiZR will grow to be a global expression of online communication. This would require permanent focus to innovate, grasp for extreme excellence and be visionary." },
        { name: "Ruhada",color:"#53A595", desc: "Working at MASTER WiZR has changed my whole perception of working remotely. The flexibility that MASTER WiZR offered to me, a way to expand my knowledge and research in different fields as creativity, design, were some of the points that make this company unique from others." },
        { name: "Nico",color:"#394A6E", desc: "Being able to present 2025 technology in 2020 is quite motivating. We have over the last years said we are digital… it was the Corona pandemic that made us digital. The way we work with sales, the product itself, and the client feedback make this a very exciting challenge." },
        { name: "Prossy",color:"#0695DB", desc: "I am adventurous, and always open to learning and trying out new things. MasterWizr presented an opportunity for me to be exposed to a new field (Business presentations), with new people and new ideas that challenge me to be creative and grow while using technology." },
        { name: "Herman",color:"#0695DB", desc: "Coming from a technical background, I have barely had a chance to prepare presentations. MW makes a person like me realize the power and possibilities of creating good and well-organized presentations. I believe in this product and here to deliver its best version." },
        { name: "Albana",color:"#53A595", desc: "The presentation designs have always made up for my lack of confidence and speech. This is why I am part of MASTER WiZR. I believe that Presentation can be empowering, hence I want to share that MW magic to empower others, all while being eco-friendly." },
    ];
    return (
        <div className={styles.container}>
            <div className={styles.innerContainer}>
                <div className={styles.info}>
                    <span>Quotes</span>
                    <h2>World team for a world market</h2>
                    <p>As a globally born company, we are inclusive of backgrounds, cultures, and beliefs while we still have a shared value in Democratizing Businesses. Our Master Team consists of a technical team, sales team, marketing team, and management team. We are also highly cross-disciplinary. We set out to evolve, always.</p>
                </div>
                <CardSlider
                    list={list}
                    renderItem={CardItem}
                    width={375}
                    boxWidth={500}
                    opacity={0.75}
                    scale={0.9}
                    disableNext={false}
                    disablePrev={false}
                    index={3}
                />
            </div>
        </div>
    );
};

export default CardSection;
