import React from 'react';
import * as styles from './quote.module.scss';
import { Image } from '../../../hooks/gatsbyImg';
import signature from '../../../images/Jone_signature.png';
const Quote = ({filename}) => {
    return (
        <div className={styles.container}>
            <Image filename={filename} className={styles.image} />
            <div className={styles.quote}>
                <h2>Sharing Re-Imagined</h2>
                <p>Inspired by the biggest brands to transform communications. MASTER WiZR is re-imagining the way we connect, share and collaborate for the professional market. We remove the limitations of other tools and provide a platform to make your content shine. Your work will be more efficient, productive, and impactful.</p>
                <p>We take the job very seriously in ensuring that Master WiZR makes a difference. We want to foster career advancement, company growth through a distinct virtual presence. We have created the best-in-class sharing and collaboration tools that are fun, engaging, and guarantee business results.</p>
                <p>We hope you enjoy using it as much as we enjoyed making it.</p>
                <p className={styles.strong}>Creative Director</p>
                <img className={styles.signature} src={signature} />
            </div>
        </div>
    );
};

export default Quote;
