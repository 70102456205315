import React from 'react';
import * as styles from './value.module.scss';

const Value = ({number, title, description, list, numberColor}) => {
    return (
        <div className={styles.container}>
            <div className={styles.number}>
                <p style={{color:numberColor}}>{number}.</p>
            </div>
            <div className={styles.text}>
                <h4>{title}</h4>
                <p>{description}</p>
                {list && <ul className={styles.list}>
                    {list.map((item) => {
                        return <li><div className={styles.circle}></div>{item}</li>;
                    })}
                </ul>}
            </div>
        </div>
    );
};

export default Value;
