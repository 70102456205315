import React, {useEffect, useState} from 'react';
import * as styles from './ColorBanner.module.scss';

const ColorBanner = () => {
    const [width, setWidth] = useState(0);
    useEffect(() => {
       setWidth(window.innerWidth);
    });
    return (
        <div className={styles.container}>
            <div className={styles.orange}>About us</div>
            <div className={styles.description}>
                <p><strong>MASTER WiZR the world’s premier presentation and meeting tool.</strong> Driven by our mission to achieve excellence we empower individuals and companies of all sizes.</p>
                <p>MASTER WiZR is re-imagining the way we connect, share and collaborate for the professional market. We remove the limitations of other tools and provide a platform to make your content shine. Your work will be more efficient, productive, and impactful.</p>
            </div>
            <div className={width > 768 ? styles.blue : styles.orange}></div>
        </div>
    );
};

export default ColorBanner;
