import * as React from "react";
import * as styles from './CardItem.module.scss';
import {Image} from '../../../hooks/gatsbyImg';

const CardItem = ({ name, desc, style,color }) => (
    <div
        style={{
            ...style, 
            backgroundColor:`${color}`
        }}
        className={styles.container}
    >
        <Image filename={`${name}.png`} width={67} height={70} />
        <p className={styles.description}>{desc}</p>
        <h3>{name}</h3>
    </div>
);

export default CardItem;
