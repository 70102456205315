export const members = [
    {name:'Jone', title:'Creative Director', image:'Jone.png', department:'Management'},
    {name:'Carsten', title:'Strategy', image:'Carsten.png', department:'Management'},
    {name:'Alborita', title:'Project Assistant', image:'Alborita.webp', department:'Management'},
    {name:'Nico', title:'CSF & Marketing', image:'Nico.png', department:'Management'},
    {name:'Adam', title:'Marketing Strategy', image:'Adam.png', department:'Marketing'},
    {name:'Leif', title:'Sales Executive', image:'Leif.png', department:'Management'},
    {name:'Jude', title:'Software developer', image:'Jude.png', department:'Technical'},
    {name:'Era', title:'Marketing', image:'Era.png', department:'Marketing'},
    {name:'Dren', title:'Software Developer', image:'Dren.png', department:'Technical'},
    {name:'Bernard', title:'Tech PQA', image:'Bernard.png', department:'Technical'},
    {name:'Harriet', title:'Tech Team', image:'Harriet.png', department:'Technical'},
    {name:'Arber', title:'Front End Developer', image:'Arber.webp', department:'Technical'},
    {name:'Jetmir', title:'Front End Developer', image:'Jetmir.png', department:'Technical'},
    {name:'Esther', title:'Programming', image:'Esther.png', department:'Technical'},
    {name:'Albana', title:'Presentations', image:'Albana.webp', department:'Marketing'},
    {name:'Labinot', title:'Recruiting & Key Accounts', image:'labinot.png', department:'Marketing'},
    {name:'Rita', title:'Tech Team', image:'Rita.png', department:'Technical'},
    {name:'Frank', title:'Designer', image:'Frank.png', department:'Marketing'},
    {name:'Blertina', title:'Graphic Designer', image:'Blertina.png', department:'Marketing'},
    {name:'Alisa', title:'Front End Developer', image:'Alisa.webp', department:'Technical'},
    {name:'Roksana', title:'Executive', image:'Roksana.png', department:'Management'},
    {name:'Josephine', title:'Executive', image:'Josephine.png', department:'Management'},
    {name:'Donjeta', title:'Executive', image:'Donjeta.png', department:'Management'},
    {name:'Herman', title:'Full Stack', image:'Herman.png', department:'Technical'},
    {name:'Gjylisha', title:'Management', image:'Gjylisha.png', department:'Management'},
    {name:'Roghashin', title:'Software Developer', image:'Roghashin.png', department:'Technical'},
    {name:'Henry', title:'Designer', image:'Henry.png', department:'Marketing'},
    {name:'Ronald', title:'Tech Team', image:'Ronald.png', department:'Technical'},
    {name:'Dua', title:'Marketing', image:'Dua.png', department:'Marketing'},
    {name:'Ruhada', title:'Digital Communications', image:'Ruhada.png', department:'Marketing'},
    {name:'William', title:'Tech Team', image:'William.png', department:'Technical'},
    {name:'Votra', title:'Front-end Developer', image:'Votra-o.png', department:'Technical'},
    {name:'Prossy', title:'Programming', image:'Prossy.png', department:'Technical'},
    {name:'Ashik', title:'Research', image:'Ashik.webp', department:'Marketing'},
    {name:'Timothy', title:'Front End Developer', image:'Timothy.png', department:'Technical'},
    {name:'Lydia', title:'Data Science', image:'Lydia.png', department:'Technical'},
    {name:'Matthew', title:'Software developer', image:'Mathew.png', department:'Technical'},
    {name:'Sadia', title:'Testing', image:'Sadia.png', department:'Technical'},
    {name:'Sumaya', title:'Presentation Services', image:'Sumaya.png', department:'Marketing'},
    {name:'Brian', title:'Tech Team', image:'Brian.png', department:'Technical'},
    {name:'Maria', title:'HR', image:'Maria.png', department:'Management'},
    {name:'Ifty', title:'Research', image:'Ifty.png', department:'Marketing'},
    {name:'Safi', title:'Research', image:'Safi.png', department:'Marketing'},
    {name:'Sadab', title:'Research', image:'Sadab.png', department:'Marketing'},
    {name:'Javed', title:'Research', image:'Javed.png', department:'Marketing'},
    {name:'Elmedin', title:'Tech Team', image:'Elmedin.png', department:'Technical'},
    {name:'Akbor', title:'Social Media', image:'Akbor.png', department:'Marketing'},
    {name:'Shad', title:'Social Media', image:'Shad.png', department:'Marketing'},
    {name:'Djellza', title:'Market Support', image:'Djellza.png', department:'Marketing'},
    {name:'Erol', title:'Computer Engineer', image:'Erol.png', department:'Technical'},
    {name:'Gresa', title:'Research Team Co-Lead', image:'Gresa.png', department:'Marketing'},
    {name:'Enes', title:'Graphic Designer', image:'Enesi.png', department:'Marketing'},
    {name:'Winnie', title:'Designer', image:'Winnie.png', department:'Marketing'},
    {name:'Fahim', title:'Social Media', image:'Fahim.png', department:'Marketing'},
    {name:'Ken', title:'Designer', image:'Ken.png', department:'Marketing'},
    {name:'Edward', title:'Designer', image:'Edward.png', department:'Marketing'},
    {name:'Timothy', title:'Front End Developer', image:'Timothy.png', department:'Technical'},
    {name:'Alphonse', title:'Technical Research', image:'Alphonse.webp', department:'Marketing'},
    {name:'Anthony', title:'Research', image:'Anthony.webp', department:'Marketing'},
    {name:'Benjamin', title:'Tech', image:'Benjamin.png', department:'Technical'},
    {name:'Mark', title:'Tech Team', image:'Mark.png', department:'Technical'},
    {name:'Sam', title:'Tech', image:'Sam.png', department:'Technical'},
    {name:'Alex', title:'Tech', image:'Alex.webp', department:'Technical'},
    {name:'Mirjeta', title:'Technical Services', image:'Mirjeta.png', department:'Technical'},
    {name:'Valon', title:'Video Animation', image:'Valoni.png', department:'Marketing'},
    {name:'Egzon', title:'Social Media', image:'Egzoni.png', department:'Marketing'},
    {name:'Gent', title:'Front End Engineer', image:'Gent.png', department:'Technical'},
    {name:'Jetmira', title:'Sales', image:'Jetmira.png', department:'Management'},
    {name:'Agnesa', title:'Front-end Developer', image:'Agnesa.png', department:'Technical'},
    {name:'Lorenta', title:'Sales', image:'Lorenta.png', department:'Management'},
    {name:'Vlera', title:'Executive Assistant', image:'Vlera.png', department:'Management'},
    {name:'Yvette', title:'Presentation Services', image:'Yvete.png', department:'Marketing'},
    {name:'Arienne', title:'Branding', image:'Arienne.webp', department:'Marketing'},
    {name:'Kujtesa', title:'Marketing', image:'kujtesa-sh.png', department:'Marketing'},
    {name:'Valmir', title:'Animation', image:'Valmir.png', department:'Marketing'},
    {name:'Azmain', title:'Tester', image:'Azmain.png', department:'Technical'},
    {name:'Faisal', title:'Tech Security Advisor', image:'Faisal.png', department:'Technical'}
];