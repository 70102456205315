import React, {useEffect} from 'react';
import Footer from "../components/atoms/footer";
import Header from '../components/atoms/header'
import HeroBanner from "../components/atoms/HeroBanner";
import ColorBanner from "../components/atoms/ColorBanner";
import Author from "../components/atoms/Author";
import Quote from "../components/atoms/Quote";
import Values from "../components/molecules/Values";
import OurTeam from "../components/ogranisms/OurTeam";
import CardSection from "../components/ogranisms/CardSection";
import {values} from '../constants/aboutUsValues';
import Head from "../components/ogranisms/head";
import AOS from "aos";
import "aos/dist/aos.css";
function AboutUs() {
    useEffect(() => {
        AOS.init({
          disable: 'mobile',
          duration : 1500
        });
      }, []);
    return (
      <>
      <Head
        title="Welcome to the WiZR world"
        description="Welcome to the WiZR world
        The place to organize, present, share and collaborate. Cultivating what matters the most to you."
        ogTitle = "About us"
        
      />
        <main>
          <Header />
            <HeroBanner filename='about-us-banner.webp' extraClass="aboutUs" >
                <h2  data-aos="zoom-in" data-aos-delay="100">Welcome to the WiZR world</h2>
                <p  data-aos="zoom-in" data-aos-delay="200">The place to organize, present, share and collaborate. Cultivating what matters the most to you.</p>
            </HeroBanner>
            <ColorBanner />
            <Quote filename='Jone-quote.jpg' />
            <Values
                title='What we really care about'
                values={values}
                extraClass='aboutUsValues'
                numberColor='#EC7600'
            /> 
            <HeroBanner  extraClass="aboutUsSecond" imageHeight='700'>
                <h2>We bring experienced professional people</h2>
                <p>Having lead Super Bowl campaigns, been account manager for a big number of Fortune 500 companies through 20 years of NYC top agencies MASTER WiZR deliver in the same league of elegance, smart concepts and excellence in communication.</p>
                <Author name="Adam" title="Marketing Strategy" filename="Adam.png" width='120px' height='120px' />
            </HeroBanner>
            <OurTeam />
            <CardSection />
            <HeroBanner filename='aboutussectionimg.webp' imageHeight={700} />
          <Footer/>
      </main>
      </>
    );
}

export default AboutUs;